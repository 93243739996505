<template>
  <div class="flex-grow scroll-area">
    <div class="content md">
      <checkin-rule-form ref="ruleForm"></checkin-rule-form>
      <a-space class="footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleConfirm">保存</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import CheckinRuleForm from "./component/CheckinRuleForm";
export default {
  name: "CheckinRuleAdd",
  components: {
    CheckinRuleForm,
  },
  methods: {
    handleCancel() {
      this.$router.back();
    },
    handleConfirm() {
      this.$refs.ruleForm.validate().then((data) => {
        this.$axios({
          url: "/admin/checkin-rule",
          method: "POST",
          data,
        }).then(() => {
          this.$message.success("保存成功！");
          this.$router.back();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
</style>
